import PropTypes from "prop-types"
import React, { useRef, useState, useEffect } from "react"

const Video = ({ src, poster, gif, className }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [videoDuration, setVideoDuration] = useState(0)
  const [isCompleted, setIsCompleted] = useState(false)

  const videoStyle = `w-full ${className}`

  const videoRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      setVideoDuration(videoRef.current.duration)
    }, 1000)
  }, [videoRef.current?.duration])
  const videoProps = {
    autoPlay: gif && "true",
    muted: gif && "true",
    loop: gif && "true",
    playsInline: "true",
    poster: poster
      ? poster
      : "https://res.cloudinary.com/fourthcanvas/image/upload/v1627450003/Case%20Studies/General/video_thumbnail_zh5av8.png",
  }

  const playVideo = () => {
    setIsPlaying(true)
    videoRef.current.play()
    setIsCompleted(false)
  }

  const pauseVideo = () => {
    videoRef.current.pause()
    setIsPlaying(false)
    setIsCompleted(false)
  }

  const monitorTime = () => {
    if (videoRef.current.ended) {
      setIsCompleted(true)
      setIsPlaying(false)
    }
  }

  return (
    <div className="relative">
      <video
        width="320"
        height="240"
        className={videoStyle}
        {...videoProps}
        ref={videoRef}
        onTimeUpdate={monitorTime}
      >
        <source src={src} type="video/mp4" />
      </video>

      {!gif && (
        <>
          {isPlaying ? (
            <button onClick={pauseVideo} className={play_pause_button_style}>
              <img
                src="https://res.cloudinary.com/fourthcanvas/image/upload/v1669917907/Case%20Studies/General/pause_ngfw75.svg"
                alt=""
                className="md:w-auto w-8 opacity-0 hover:opacity-100 "
              />
            </button>
          ) : (
            <button onClick={playVideo} className={play_pause_button_style}>
              <img
                src="https://res.cloudinary.com/fourthcanvas/image/upload/v1666181040/Case%20Studies/General/play-icon_jugdtx.svg"
                alt=""
                className="md:w-auto w-8  "
              />
            </button>
          )}
          <div
            className={`absolute left-0 right-0 top-0 bottom-0 w-full h-full bg-black/25 transition-opacity duration-300 flex items-center justify-center z-0 ${
              isPlaying ? "opacity-0 peer-hover:opacity-100" : "opacity-100 "
            } `}
          ></div>

          {Boolean(videoDuration) && (
            <ProgressBar
              duration={videoDuration}
              isPlaying={isPlaying}
              isCompleted={isCompleted}
            />
          )}
        </>
      )}
    </div>
  )
}

const ProgressBar = ({ duration, isPlaying, isCompleted }) => {
  return (
    <div className="absolute md:bottom-[40px] bottom-[16px] left-[5%] right-[5%] md:h-2 h-1 bg-white w-[90%] rounded-lg overflow-hidden ">
      <div
        className={`h-full rounded-[50px] bg-black transition-[width] ease-linear  ${
          !isCompleted ? "video-progress" : ""
        } `}
        style={{
          animationDuration: `${duration}s`,
          animationPlayState: isPlaying ? `running` : "paused",
        }}
      ></div>
    </div>
  )
}

const play_pause_button_style =
  "peer inline-block absolute left-[calc(50%-16px)] md:left-[calc(50%-51px)]  top-[calc(50%-58.5px)] top-[calc(50%-18.3px)] z-10 "

Video.propTypes = {
  src: PropTypes.string,
  poster: PropTypes.string,
  gif: PropTypes.bool,
}

export default Video
