import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import BodyTitleContent from "../../utils/title-content"
import OneSideImage from "../single/one-side-image"
import ImageGrid from "../single/image-grid"

const FilmoRealty = () => {
  return (
    <>
      <BodyTitleContent
        heading="Getting into the drive"
        content="The brief was clear—a fine blend of experience and modern innovativeness. So, we dived in, established an understanding with the leadership of Filmo on her brand attributes (target client, brand personality, brand focus), competition and the objective of this rebrand."
        stacked
      />

      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065500/Case%20Studies/Filmorealty/FilmoRealty-10-1_xsp3q8.jpg" />

      <BodyTitleContent
        content="We then moved on to the next phase, to research the Property & Facility Management industry. A major part of our process involves immersing ourselves in the client’s position (empathy) and deriving a direction for the identity that is focused on helping the company clearly distinguish itself as a partner of choice in the industry"
        stacked
      />

      {/* Modern, yet bold */}
      <BodyTitleContent
        heading="Modern, yet bold"
        content="In order to arrive at an identity that works, we converted brand abstracts and ideas into visual languages. In several sketches and iterations, we co-created a sleek typography that is simple and non traditional, Sans Serif, with the goal of achieving a modern, yet bold identity."
        stacked
      />

      <ImageGrid>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065498/Case%20Studies/Filmorealty/FilmoRealty-12_irk6gc.jpg" />

        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065500/Case%20Studies/Filmorealty/FilmoRealty-09_cahtfo.jpg" />
      </ImageGrid>

      {/* Retaining the core  */}
      <BodyTitleContent
        heading="Retaining the core"
        content="We were careful to retain the spirit and charm of the existing logo while evolving the brand to appeal to a broader audience. We stuck with the red colour from the previous identity and combined it with a premium grey colour."
        stacked
      />

      <ImageGrid>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065501/Case%20Studies/Filmorealty/FilmoRealty-Red_hxvso5.jpg" />

        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065498/Case%20Studies/Filmorealty/FilmoRealty-Grey_lbspyr.jpg" />
      </ImageGrid>

      {/* Type in bold */}
      <BodyTitleContent
        heading="Type in bold"
        content="Standing tall and bold is the focal point of the Filmo typeface: A forward thinking and modern identity for the evolution of property management among her peers.."
        stacked
      />

      <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065496/Case%20Studies/Filmorealty/FilmoRealty-08-scaled_syuy0l.jpg" />

      {/* A mark for innovation */}
      <BodyTitleContent
        heading="A mark for innovation"
        content="The mark is incorporated into the typography as a breath of modernness, simplicity and memorability. It incorporates letters “F” and “R” in a single body. The lid at the top of the mark symbolizes the edge in innovation and the leadership that the brand typifies. There is also a sense of motion in the relationship between the two horizontal bars of the mark."
        stacked
      />

      <image
        loading="lazy"
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065502/Case%20Studies/Filmorealty/Filmorealty-Logo-Transition_hkfbhz.gif"
      />

      <ImageGrid>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065494/Case%20Studies/Filmorealty/FilmoRealty-03_frftzk.jpg" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065494/Case%20Studies/Filmorealty/FilmoRealty-06_bkqvjy.jpg" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065500/Case%20Studies/Filmorealty/FilmoRealty-Letterhead_k2wzm0.jpg" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065502/Case%20Studies/Filmorealty/FilmoRealty-Shirt_mhaeal.jpg" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065499/Case%20Studies/Filmorealty/FilmoRealty-ID-Card_nglmzr.jpg" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065497/Case%20Studies/Filmorealty/FilmoRealty-Business-Card_hukllz.jpg" />
      </ImageGrid>
    </>
  )
}

export default FilmoRealty
