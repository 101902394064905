import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const PenneeScrollShowcase = () => {
  gsap.registerPlugin(ScrollTrigger)
  const imageCollageWrapper = useRef()

  const imageCollage = useRef()

  useEffect(() => {
    setTimeout(() => {
      const imageDistance =
        imageCollage.current.getBoundingClientRect().width -
        imageCollageWrapper.current.getBoundingClientRect().width

      gsap.to(imageCollage.current, {
        x: () => `-${imageDistance}`,
        duration: 10,
        scrollTrigger: {
          trigger: ".image-collage-wrapper",
          // markers: true,
          start: "top top",
          end: () => `${imageDistance * 2}`,
          ease: "none",
          scrub: 1,
          pin: true,
          invalidateOnRefresh: true,
        },
      })
    }, 1000)
  }, [])
  return (
    <>
      <div
        ref={imageCollageWrapper}
        className=" image-collage-wrapper md:block hidden  pt-24 overflow-x-hidden"
      >
        <div ref={imageCollage} className=" w-max h-[calc(100vh-144px)] ">
          <img
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1627396906/Case%20Studies/Pennee/Creativity_strip_n7hqaw.jpg"
            alt="Pennee case studies strip"
            className="h-full transform "
          />
        </div>
      </div>
    </>
  )
}

export default PenneeScrollShowcase
