import React from "react"

const OneSideImage = ({
  image,
  altText = "",
  left = false,
  center,
  children,
  className,
}) => {
  return (
    <section
      className={
        `flex flex-col ` +
        (left
          ? "lg:flex-row-reverse lg:px-16 px-0 "
          : "lg:flex-row lg:px-16 px-0 ") +
        className
      }
    >
      <section className="lg:w-3/12 lg:pr-20"></section>

      <section className={center ? `sm:w-6/12` : `lg:w-9/12`}>
        {image && <img src={image} alt={altText} className="w-full block" />}
        {children}
      </section>

      {center ? <section className="sm:w-3/12" /> : null}
    </section>
  )
}

export default OneSideImage
