import React from "react"

const PageContainer = ({ children, bg, className }) => {
  return (
    <section
      className={`page-container flex md:justify-center justify-start items-start flex-wrap  lg:px-16 px-8 my-20  bg-[${bg}] ${className}`}
    >
      {children}
    </section>
  )
}

export default PageContainer
