import React from "react"
import Piggyvest from "./piggyvest"
import Fundall from "./fundall"
import Filmorealty from "./filmorealty"
import NigerianWomenTechsters from "./nigerian-women-techsters"
import Ondipa from "./ondipa"
import TitanTrustBank from "./titan-trust"
import DevelopOndo from "./develop-ondo"
import Pennee from "./pennee"
import Youverify from "./youverify"
import Nomba from "./nomba"

const caseStudies = {
  piggyvest: <Piggyvest />,
  fundall: <Fundall />,
  filmorealty: <Filmorealty />,
  "nigerian-women-techsters": <NigerianWomenTechsters />,
  ondipa: <Ondipa />,
  "titan-trust": <TitanTrustBank />,
  "develop-ondo": <DevelopOndo />,
  pennee: <Pennee />,
  youverify: <Youverify />,
  nomba: <Nomba />,
}

export default caseStudies
