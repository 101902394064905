import React from "react"

import BodyTitleContent from "../../../utils/title-content"

import FundallAnimatedIcons from "./animated-icons"
import FundallTypographyIconography from "./typography_iconography"
import PageContainer from "../../../utils/page-container"
import FundallScrollShowcase from "./scroll-showcase"

const Fundall = () => {
  return (
    <>
      <BodyTitleContent>
        <h2>Building from the core</h2>
        <p>
          Our audit began with the existing visual identity system which made a
          good starting point. Our intention was clear—to expand and translate
          the identity, taking it further in expression across the platforms at
          hand, while keeping the core.
        </p>
      </BodyTitleContent>

      {/* Lottie Icons Animation */}

      <FundallAnimatedIcons />

      <BodyTitleContent content="Key points of our translation, among several other subtle design decisions, included the theme for the iconography system as well as the U-shaped container that featured both as section dividers and also inspired the button, both of which were directly inspired by the logo and visual identity." />

      <FundallTypographyIconography />

      {/* TODO: Update landing page images */}
      {/* <PageContainer>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625150859/Case%20Studies/Fundall/Fundall_Homepage_qms3pb.png" />
      </PageContainer> */}

      {/* TODO: Update Mockup or unhide */}
      {/* <PageContainer>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625150860/Case%20Studies/Fundall/PC_and_mobile_view_dh4f3w.png" />
      </PageContainer> */}

      <FundallScrollShowcase />

      <BodyTitleContent>
        <h2>Touching every point</h2>
        <p>
          From the information architecture, to the copywriting, illustrations,
          the application of the expanded visual identity system to the user
          interface design, we achieved a modern and clean platform, across web
          and mobile, that is brand-centric, while delivering a seamless
          experience for the user.
        </p>
      </BodyTitleContent>

      <PageContainer>
        <img
          alt="Fundall Animated Dashboard"
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625155322/Case%20Studies/Fundall/AnimatedDashboards_ldntsq.gif"
        />
      </PageContainer>

      <BodyTitleContent>
        <h2>
          "This is the most exciting branding project I have been a part of, and
          I am talking about several years of similar engagements. The approach
          was world-class and the collaboration was seamless."
        </h2>
        <p>Taiwo Obasan, CEO</p>
      </BodyTitleContent>

      <BodyTitleContent content="The best part of our work with Fundall is that it continues via a Brand Management partnership through which we are able to continue to collaborate with the team to improve and express the brand both in terms of the experince as well as overall brand communication." />
    </>
  )
}

export default Fundall
