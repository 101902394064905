import React from "react"
// import WorkLayout from "../../layout/work"
import { graphql } from "gatsby"
import WorkIntro from "./intro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import caseStudies from "../case-studies"
import WorkPreview from "../../utils/work-preview"
import PageContainer from "../../utils/page-container"

import loadable from "@loadable/component"

const WorkLayout = loadable(() => import("../../layout/work"))

export const singleWorkQuery = graphql`
  query singleWorkQuery($slug: String!) {
    wpWork(slug: { eq: $slug }) {
      title
      content
      slug
      featuredImage {
        node {
          mediaItemUrl
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }

      projectTypes {
        nodes {
          name
        }
      }

      workFields {
        clientprojectName
        metaDescription
        introHeading
      }
    }

    allWpWork(limit: 2, filter: { slug: { ne: $slug } }) {
      nodes {
        title
        content
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }

        workFields {
          clientprojectName
          introHeading
        }
      }
    }
  }
`
const SingleWorkPage = ({ data }) => {
  const work = data.wpWork
  const cover_image = work.featuredImage.node
    ? getImage(work.featuredImage.node.localFile.childImageSharp)
    : null

  const more_works = data.allWpWork.nodes

  const show_more_works = more_works.map((work, i) => (
    <WorkPreview work={work} key={i} navigation />
  ))

  const work_fields = work.workFields

  const seo = {
    title: work.title,
    description: work_fields.metaDescription,
    meta: [
      {
        property: "og:image",
        content: work.featuredImage.node.mediaItemUrl,
      },
    ],
  }
  return (
    <WorkLayout seo={seo} fallback={<p>Loading...</p>}>
      <GatsbyImage image={cover_image} alt={work.title} className="w-full " />
      <WorkIntro
        client={work_fields.clientprojectName}
        title={work_fields.introHeading}
        description={work.content}
        projectType={work.projectTypes.nodes}
      />
      {caseStudies[work.slug]}

      <PageContainer>
        <div className="w-full mt-12 ">
          <h2 className="font-light opacity-30">Some More...</h2>
        </div>
      </PageContainer>
      <section className="-mt-16">{show_more_works}</section>
    </WorkLayout>
  )
}

export default SingleWorkPage
