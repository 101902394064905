import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import BodyTitleContent from "../../utils/title-content"
import OneSideImage from "../single/one-side-image"
import ImageGrid from "../single/image-grid"

const DevelopOndo = () => {
  return (
    <>
      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065492/Case%20Studies/Develop%20Ondo/Develop-Ondo-Newspaper-Ad-01_cfusrz.jpg" />

      <BodyTitleContent
        content="
          In line with this, the Ondo State Government via the Ministry of Economic Planning and Budget engaged us to effectively showcase via visual communications a readiness to work with national and international development partners for the advancement of the state"
        stacked
      />

      {/*The ray you can see */}
      <BodyTitleContent
        title="The ray
you can see"
        content={
          <>
            <p>
              The Brand Identity for Develop Ondo is an open visual identity
              system that gives room for a stretch of dynamics from the simple
              core of driving engagement. It readily answers the call of “This
              is why we want to work with you”.
            </p>
            <p>
              Understanding that the state is committed to showcasing its
              readiness to explore ideas and policies that will foster
              investment; Develop Ondo in its design and expression has an
              active tone that should spur people to action.
            </p>
          </>
        }
        stacked
      />

      <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065491/Case%20Studies/Develop%20Ondo/Develop-Ondo-Logo-scaled_pevrhb.jpg" />

      {/*DO it*/}
      <BodyTitleContent title="DO it" stacked>
        <>
          <p>
            An important part of the campaign was engaging all relevant
            stakeholders—including the people—in this quest to Develop Ondo. A
            call that people could rally around and can inspire people to take
            solution-driven actions.
          </p>
          <p>
            We found a jackpot in the name “Develop Ondo” - the first letter in
            each word formed a new word “DO”, an action word that we played on
            for further expressions of the identity for the campaign. Learning
            from the Nike campaign of “Just Do it”, we had to do it, this time,
            to “Develop Ondo”
          </p>
        </>
      </BodyTitleContent>

      <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065491/Case%20Studies/Develop%20Ondo/Develop-Ondo-Icon-scaled_neyq4j.jpg" />

      <BodyTitleContent title="Ondo is Orange..." stacked>
        <>
          <p>
            The end result is a unified identity system that has the potential
            to expand indefinitely.
          </p>
          <p>
            We have helped the Ondo State Government to build on its intentions
            with a readily available communication template to engage all
            relevant stakeholders. This includes encouraging development
            partners to do more and for the people to be ready to partner with
            the company to do more.
          </p>
          <p>
            “DO” is an important word in our everyday vocabulary. The identity
            opens an endless possibility of opportunities to ensure the mind
            share of relevant stakeholders so that when they think do, they
            think “ Develop Ondo”.
          </p>
        </>
      </BodyTitleContent>

      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065492/Case%20Studies/Develop%20Ondo/Develop-Ondo-Lamp-Post-scaled_hlexyp.jpg" />
      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065495/Case%20Studies/Develop%20Ondo/Develop-Ondo-Logo-Animation_oc2wry.gif" />
      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065496/Case%20Studies/Develop%20Ondo/Develop-Ondo-Web-Page_isv1yp.jpg" />

      <ImageGrid>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065491/Case%20Studies/Develop%20Ondo/Develop-Ondo-Brochure-Cover_sfegdi.jpg" />

        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065491/Case%20Studies/Develop%20Ondo/Develop-Ondo-Brochure-Inside_fsgcr9.jpg" />
      </ImageGrid>

      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065497/Case%20Studies/Develop%20Ondo/Develop-Ondo-Newspaper-Ad_xp6qi8.jpg" />
      <OneSideImage
        image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065495/Case%20Studies/Develop%20Ondo/Develop-Ondo-Rollup-Banner_wz19qo.jpg"
        left
      />
    </>
  )
}

export default DevelopOndo
