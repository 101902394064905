import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import BodyTitleContent from "../../utils/title-content"
import OneSideImage from "../single/one-side-image"
import ImageGrid from "../single/image-grid"
const TitanTrustBank = () => {
  return (
    <>
      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065516/Case%20Studies/Titan%20Trust%20Bank/Titan-Trust-Bank-Identity_Icon_FourthCanvas-1_q2fyua.gif" />

      <BodyTitleContent
        content="
In search for local answers upon a number of attempts to get this done earlier on the project timeline, we were engaged in a collaboration with OneGuy to develop a logo that retains the values inherent in the name and goes further to suggest the attitude of speed and innovation. While the name did the good job of inspiring reliability, that was all it did and we agreed earlier on with the client that the logo had to make up for this to attain a balance. It had to maximise colour, shapes, angle and composition to be very relatable for young people who form a big part of the target customers."
      />

      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065513/Case%20Studies/Titan%20Trust%20Bank/Titan-Trust-Bank-Identity-FourthCanvas-Logo-Clearspace--scaled_ijeaol.jpg" />

      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065512/Case%20Studies/Titan%20Trust%20Bank/Titan-Trust-Bank-Identity_FourthCanvas-13-scaled_kzserf.jpg" />

      <BodyTitleContent content="Our final logo—upon several expressions and phases of iteration—is the result of a fusion of 3 letter Ts that combine into one forward-leaning and agile 'Titan T’. The entire composition is free of corners or blockages to represent the flexibility of the bank. This is designed to immediately earn admiration for its dynamism, while retaining the brand’s inherent value of stability." />

      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065518/Case%20Studies/Titan%20Trust%20Bank/Titan-Trust-Building-scaled_q69ex9.jpg" />
      <OneSideImage
        image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065518/Case%20Studies/Titan%20Trust%20Bank/Titan-Trust-Interior-1-scaled_smou8c.jpg"
        left
      />
      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065517/Case%20Studies/Titan%20Trust%20Bank/Titan-Trust-Bank-Office_bfzlwx.jpg" />

      <BodyTitleContent
        content="
One critical part of the design process was colour. The most distinctive Nigerian bank brands stand out for colour among many other things. We opted for a mix, which tied into our objective for dynamism. The orange—youthful and vibrant—is the highlight which combines with two other calmer colours to create an overall colour theme that is distinct from all existing banks."
      />

      <ImageGrid>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065520/Case%20Studies/Titan%20Trust%20Bank/Titan-Trust-Bank-Identity_FourthCanvas-22-scaled_mb1bpi.jpg" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065516/Case%20Studies/Titan%20Trust%20Bank/Titan-Trust-Bank-Identity_FourthCanvas_Internal-Signage-scaled_bbgabu.jpg" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065512/Case%20Studies/Titan%20Trust%20Bank/Titan-Trust-Bank-Identity_FourthCanvas-17-scaled_dtuhvg.jpg" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065517/Case%20Studies/Titan%20Trust%20Bank/Titan-Trust-Bank-Identity_FourthCanvas-21-scaled_rlqd21.jpg" />
      </ImageGrid>

      <BodyTitleContent content="As a newly launched bank in a fully saturated industry among well-established bigwigs, Titan is finding its foot and getting identified with its new brand identity— distinct and enthralling. As TTB continues core practice just about a year after launch, the identity leads the way into their goals of customer acquisition as an identifier of all things the bank stands for." />

      <div>
        <image src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065515/Case%20Studies/Titan%20Trust%20Bank/Titan-Trust-Bank-Identity_FourthCanvas-26-scaled_k0r5uz.jpg" />
      </div>
    </>
  )
}

export default TitanTrustBank
