import React from "react"
import BodyTitleContent from "../../../utils/title-content"
import CaseStudyCreditItem from "./item"

const CaseStudyCredits = ({ people = [], color, shoutout }) => {
  const headingStyle = `uppercase font-display font-bold   mb-6 ${color} `
  return (
    <BodyTitleContent>
      <p className={headingStyle}>Credits</p>
      <ul>
        {people.map((person, index) => (
          <CaseStudyCreditItem person={person} key={index} />
        ))}
      </ul>
      <p>{shoutout}</p>
    </BodyTitleContent>
  )
}

export default CaseStudyCredits
