import React from "react"
import BodyTitleContent from "../../utils/title-content"

const CaseStudyComment = ({ comment, by }) => {
  return (
    <BodyTitleContent>
      <h2 className="md:text-4xl text-2xl leading-[1.1]">“{comment}”</h2>
      <p>- {by}</p>
    </BodyTitleContent>
  )
}

export default CaseStudyComment
