import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import BodyTitleContent from "../../../utils/title-content"
import Video from "../../../utils/video"
import CaseStudyComment from "../../single/comment"
import CaseStudyCredits from "../../single/credits"

const Youverify = () => {
  return (
    <>
      <img
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617078/Case%20Studies/YouVerify/YV-Stacks__dihpmy.gif"
        alt="Youverify Stacks"
      />
      <BodyTitleContent
        content="Listening through the journey to establishment and so far, we were able to see through the layer of technology to highlight a persona of confidence, warmth, and welcome in its work and walk with people through what would otherwise appear like a difficult journey towards simplicity. While visual identity headlined the brief, we would also go ahead to write, design, and develop the new website to ensure an experience that helped to communicate its wide range of solutions, while saving potential clients all the complexity.   "
        stacked
      />

      <Video
        poster="https://res.cloudinary.com/fourthcanvas/image/upload/v1645636241/Case%20Studies/YouVerify/YV_Website_Preview_Cover__efxmrb.jpg"
        src="https://res.cloudinary.com/fourthcanvas/video/upload/v1645617528/Case%20Studies/YouVerify/YV_Website_w8sbvq.mp4"
      />

      <BodyTitleContent
        heading="An identity for identity (no typos)"
        content="We created a visual identity that builds upon the most basic shape synonymous with identity—think about SIM cards, price tags, data chips, key holders, and more—and extends to a full-fledged visual language that is easily recognizable across the board. We also crafted the custom YOUVERIFY wordmark with some inspiration from the typical barcode symbol and extended this into a full-fledged type family."
        stacked
      />

      <img
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617056/Case%20Studies/YouVerify/YV_Logo_Construction_a1wyje.gif"
        alt="Youverify Logo Construction"
      />
      <img
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617088/Case%20Studies/YouVerify/YV_Footer_qrsqan.gif"
        alt="Youverify Footer"
      />

      <BodyTitleContent
        content="With an “ice-cool” colour scheme working closely with a carefully curated typography system, we created a set of key identity assets including unique patterns, icons, and visual identifiers that make it easy to identify the brand across the board."
        stacked
      />

      <img
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617063/Case%20Studies/YouVerify/YV-Colour_nnamjt.gif"
        alt="Youverify Colour"
      />
      <img
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617056/Case%20Studies/YouVerify/YV-Tags_vrncot.gif"
        alt="Youverify Tags"
      />
      <img
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617063/Case%20Studies/YouVerify/YV-Icon___pjgxkp.gif"
        alt="Youverify Icon"
      />
      <BodyTitleContent
        heading="Typography elegance, 
inspired by barcodes  "
        content="We crafted the custom YOUVERIFY wordmark with some inspiration from the typical barcode symbol and extended this into Youverify Std, a full-fledged type family that comes to life in the YV tags bearing nouns. 
"
        stacked
      />
      <img
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617144/Case%20Studies/YouVerify/YV_Type_gcaibr.gif"
        alt="Youverify Type"
      />
      <StaticImage
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617055/Case%20Studies/YouVerify/YV_Type-15_ipbafo.jpg"
        alt="Youverify Type"
      />
      <BodyTitleContent
        heading="Come alive, distinct"
        content="We designed and developed the new Youverify website ensuring that all components—from voice, content, identity, and a set of unique illustrations we created for the brand—tie up as imagined. Beyond expressing the brand identity, delivering the website involved a great deal of sorting through complexity with the volume of information that needed to be communicated.
"
        stacked
      />

      <img
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617191/Case%20Studies/YouVerify/Illustrations-YV_okpjya.gif"
        alt="Youverify Illustrations"
      />
      <StaticImage
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617091/Case%20Studies/YouVerify/YV_LED_BillBoard_ndd0bp.jpg"
        alt="Youverify LED Billboard"
      />
      <StaticImage
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617083/Case%20Studies/YouVerify/YV_Mark_ksqxg4.jpg"
        alt="Youverify Mark"
      />
      <StaticImage
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617193/Case%20Studies/YouVerify/YV_Social_Media_xq4bmj.jpg"
        alt="Youverify Social Media flyers"
      />
      <StaticImage
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617067/Case%20Studies/YouVerify/YV_Cards_ooszmt.jpg"
        alt="Youverify Cards"
      />

      <BodyTitleContent
        content="Speed, security, and sustainability were major factors towards the team’s marketing objectives and our engineering team was there to ensure it worked as good as it looked. One major highlight as we aimed at a seamless flow with the existing infrastructure, was successfully integrating the team's preferred marketing tool, SharpSpring with GatsbyJS, for which there wasn’t an official plugin at that moment.
"
        stacked
      />

      <StaticImage
        src="https://res.cloudinary.com/fourthcanvas/image/upload/v1645617189/Case%20Studies/YouVerify/Youverify_Brand_Guideline-04_jgntrd.jpg"
        alt="Youverify Brandguideline"
      />

      <BodyTitleContent
        content="As Youverify expands the reach of its mission across the continent, the new brand identity continues to impact its position, perception, and sense of pride within and without."
        stacked
      />

      <CaseStudyComment
        comment="Working with FourthCanvas made it easier for us to pull this off. What you get with them is the WOW factor."
        by="Gbenga Odegbami"
      />

      <CaseStudyCredits
        color="text-youverify-blue"
        people={[
          {
            name: "Tunji Ogunoye",
            role: "for leading our team on the project",
          },
          {
            name: "Opeyemi Olugbemiro",
            role: "for charting the course with brand strategy",
          },
          {
            name: "Dunsin Ayodele",
            role: "for brilliant creative expression (still and motion)",
          },
          {
            name: "Ademola Aderibigbe",
            role: "for the beauty of illustrations",
          },
          {
            name: "Aremu Oluwagbamila & team",
            role: "for engineering excellence",
          },
        ]}
        shoutout={
          <>
            <b>
              Gbenga Odegbami, Seyi Akinkugbe, Famous Ehichioya (and other team
              members from Youverify)
            </b>
            —for trusting us when the project needed it the most.
          </>
        }
      />
    </>
  )
}

export default Youverify
