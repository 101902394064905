import React from "react"

const ImageGrid = ({ children, columns = 2, gap, className }) => {
  return (
    <section
      className={`grid  grid-cols-1 ${columnVariants[columns]} ${
        gap && "gap-4"
      } ${className}`}
    >
      {children}
    </section>
  )
}

const columnVariants = {
  1: "sm:grid-cols-l",
  2: "sm:grid-cols-2",
  3: "sm:grid-cols-3",
  4: "sm:grid-cols-4",
  5: "sm:grid-cols-5",
  6: "sm:grid-cols-6",
}

export default ImageGrid
