import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { StaticImage } from "gatsby-plugin-image"
import PageContainer from "../../../../utils/page-container"

const FundallScrollShowcase = () => {
  gsap.registerPlugin(ScrollTrigger)
  const imageCollageWrapper = useRef()

  const imageCollage = useRef()

  useEffect(() => {
    const imageDistance =
      imageCollage.current.getBoundingClientRect().width -
      imageCollageWrapper.current.getBoundingClientRect().width

    setTimeout(() => {
      gsap.to(imageCollage.current, {
        x: () => `-${imageDistance}`,
        duration: 10,
        scrollTrigger: {
          trigger: ".image-collage-wrapper",
          // markers: true,
          start: "top top",
          end: () => `${imageDistance * 4}`,
          scrub: 1,
          pin: true,
          invalidateOnRefresh: true,
        },
      })
    }, 500)
  }, [])
  return (
    <>
      <div
        ref={imageCollageWrapper}
        className="bg-fundall-green image-collage-wrapper  pt-36 overflow-x-hidden"
      >
        <div ref={imageCollage} className="w-[2500px]   flex">
          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625221756/Case%20Studies/Fundall/Fundall-Website-Screens-Collage-FourthCanvas_otwlau.png"
            alt="Fundall Dashboard"
            className="h-[calc(100vh-144px)] transform "
          />
          {/* <img
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625221756/Case%20Studies/Fundall/Fundall-Website-Screens-Collage-FourthCanvas_otwlau.png"
            alt="Fundall Website screenshots"
          /> */}
        </div>

        <section className="pt-24 ">
          <PageContainer className="mb-0">
            <StaticImage
              src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625150859/Case%20Studies/Fundall/Fundall_Dashboard_zqjdpd.png"
              alt="Fundall Dashboard"
            />
          </PageContainer>
        </section>
      </div>
    </>
  )
}

FundallScrollShowcase.propTypes = {}

export default FundallScrollShowcase
