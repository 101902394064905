import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import BodyTitleContent from "../../utils/title-content"

const Ondipa = () => {
  return (
    <>
      <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065503/Case%20Studies/Ondipa/Ondipa-Website-01-scaled_v4rqbs.jpg" />

      <BodyTitleContent>
        <>
          <p>
            The goal was to show the readiness of the state to connect, be open
            to investments and other projects that will lead to the
            socio-economic transformation of the state.
          </p>

          <p>
            The government needed an agency to be at the forefront of this drive
            and we came in to deliver a brand identity that readily communicated
            the intentions of the state and the opportunities therein.
          </p>

          <p>
            The Ondo Development and Investment Promotion Agency (ONDIPA) was
            born and we were saddled with the responsibility to give it an
            identity.
          </p>
        </>
      </BodyTitleContent>

      <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065505/Case%20Studies/Ondipa/Ondipa-Website-03-1-scaled_ysiefq.jpg" />

      <BodyTitleContent
        heading="The sun still shines"
        content="The client wanted to stick with some of the existing visual features that the state is known for—like the orange colour and the “O” with the sunrays. We kept this in mind, as we worked on an identity system that reflected the Ondo’s readiness to attract valuable investment."
        stacked
      />
      <BodyTitleContent heading="For ONDIPA, we went deeper" stacked>
        <p>
          The agency was set up by the government to execute plans and projects
          around investment and development.
        </p>

        <p>
          With this in view, we depicted the state as a nodal point between
          geopolitical zones—access points to the rest of the country; the hub
          of investment opportunities and the immediate need to unlock the
          various potential opportunities.
        </p>
      </BodyTitleContent>

      <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065505/Case%20Studies/Ondipa/Ondipa-Website-03-1-scaled_ysiefq.jpg" />

      <BodyTitleContent>
        <p>
          With this in view, we had to depict the state as a nodal point between
          geopolitical zones—access points to the rest of the country; the hub
          of investment opportunities and the immediate need to unlock the
          various potential points of the state.
        </p>

        <p>
          It had to be deep and we created an ONDIPA identity that went deeper!
        </p>
      </BodyTitleContent>
      <BodyTitleContent heading="Giving the Sunshine State a new ray" stacked>
        <p>
          The brand identity stands out as the individual elements of the logo,
          carrying the different messages and combines well to project the state
          as a hub of investment, whilst also readily communicating the
          available opportunities.
        </p>

        <p>
          It has helped to distinguish the agency as a top-notch partner in
          development— it is distinctive, readily communicates what the agency
          stands for and is an identity that clients, employees and the people
          can proudly showcase.
        </p>
      </BodyTitleContent>

      <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065503/Case%20Studies/Ondipa/Ondipa-Website-02-scaled_audx9c.jpg" />

      <BodyTitleContent
        content="
We did not stop at just delivering a logo, we went further to create an identity based iconography that covered in depth the operations of the agency. The customised icons meant that, at any level of interaction with the touchpoints of ONDIPA, people could see a harmonised detail of the set objectives of the brand."
        stacked
      />

      <BodyTitleContent>
        <img
          alt="ONDIPA icons animation"
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065503/Case%20Studies/Ondipa/ONDIPA-Motion_lhswni.gif"
        />
      </BodyTitleContent>

      <BodyTitleContent
        heading="A pattern that shows the feel"
        content="We also developed well thought out creative patterns aimed at etching ONDIPA in the minds of people who came across the identity. At different levels of the agency, there was something to interact with—a logo, the icons or the patterns. This gives a reinforcing experience and creates an indelible memory when brand assets related to the agency are displayed."
      />

      <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065504/Case%20Studies/Ondipa/Ondipa-Website-12-scaled_fxebqi.jpg" />

      <BodyTitleContent
        content="
An identity that is rich is the least we could do to represent the vast possibilities of investment opportunities in Ondo State. We gave the sunshine state, a new ray."
      />
    </>
  )
}

export default Ondipa
