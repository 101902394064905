import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import BodyTitleContent from "../../utils/title-content"
import OneSideImage from "../single/one-side-image"
import ImageGrid from "../single/image-grid"

const Piggyvest = () => {
  return (
    <>
      <BodyTitleContent heading="Building upon a foundation" stacked>
        <>
          <p>
            Piggyvest had a lot going right, and we were curious to learn as
            much as possible to begin with. We had several sessions with the
            team to understand the story of the brand, and get their
            perspectives and experiences working from within.
          </p>

          <p>
            We discussed the vision, personality and positioning for the brand.
            Simultaneously, we held virtual interviews with a number of users,
            including those who currently use alternative platforms to Piggyvest
            to understand perceptions and expectations from their end. At the
            end of the initial research and understanding phase, it was clear
            what mattered the most—helping young people achieve financial
            freedom—and how we would communicate it.
          </p>
        </>
      </BodyTitleContent>

      <BodyTitleContent>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065505/Case%20Studies/Piggyvest/Piggyvest-Icon-Change-1-scaled_uv5shu.jpg" />
      </BodyTitleContent>

      <BodyTitleContent
        heading="People first"
        content="As a financial brand gaining increasing trust, Piggyvest decided against having a drastic logo change and we agreed with the rationale. We focused on finding opportunities that simply made the same logo more meaningful."
        stacked
      />

      <BodyTitleContent>
        <>
          <img
            alt="piggyvest old logo to new animation"
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065514/Case%20Studies/Piggyvest/Piggyvest-Logo-_-Old-to-New_kpio8q.gif"
          />
        </>
      </BodyTitleContent>

      <BodyTitleContent content="The old logo suggested a door lock and a padlock which over-emphasized “savings and locking it up”. While this is true, we believe that when it comes to people attaining financial freedom, the lock is simply one of the tools on the path to making this possible." />

      <BodyTitleContent>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065506/Case%20Studies/Piggyvest/Piggyvest-Old-Logo-scaled_umw0kc.jpg" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065508/Case%20Studies/Piggyvest/Piggyvest-New-Icon-scaled_ifpmxo.jpg" />
      </BodyTitleContent>

      <BodyTitleContent
        content="
We noticed a solution that wasn’t too far off; there was the keyhole right in the middle of the logo, which we literally picked and tweaked to take a human form, emphasising that the brand is people-centric. This led to the final design for the symbol, out of several other iterations. We also increased the curves on the edges to increase the appeal and overall friendliness. Typography was another aspect we looked into."
      />

      <BodyTitleContent>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065506/Case%20Studies/Piggyvest/Piggyvest-Logo-Type-scaled_payxln.jpg" />
      </BodyTitleContent>

      <BodyTitleContent
        heading="piggyvest (formerly PiggyVest)"
        content="Upon testing and prototyping hundreds of possibilities over a long time (so long you won’t believe, actually), we found U8, which fits in perfectly as the new type, with better recall and identification than the previous. We also agreed on a full small casing to help the brand’s unique hybrid name combination. The logo reads “piggyvest”, also right as Piggyvest in writing, but no longer “PiggyVest”."
        stacked
      />

      <BodyTitleContent>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065508/Case%20Studies/Piggyvest/Piggyvest-Old-to-New-Logo-1-scaled_sbfhq6.jpg" />
      </BodyTitleContent>

      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065513/Case%20Studies/Piggyvest/Piggyvest-Typeface-scaled_gisiap.jpg" />

      <BodyTitleContent
        heading="Unblocking colour"
        content="While Piggyvest is loved for its variety of options to save and invest, we needed a visual system that represented this across all channels including social media, prints and merchandise. While the blue—now tweaked to a slightly brighter hue we named Piggy Blue—remains the official colour, the new visual system embraces more colours in the patterns and elements that will feature in ads and posters. The new colour system also brings in some more vibrancy to the overall look and feel."
        stacked
      />
      <BodyTitleContent
        heading="Beds of treasures"
        content="We created a new pattern (for application on stationery, interface, ephemera/merch and more) based on the idea of an abstract visual representation of prosperity and wealth. It is formed by an endless array of neatly packed and similarly grouped objects based on 3 abstract shapes culled from the Piggyvest logo and represents conscious savings and growth of wealth through the multiple options available. The wavy lines/loops represent a flow of wealth and suggest progress. The overall visual is layered in “beds” that suggest different layers of treasures—which connects with the overall vision of wealth and a secure future for Piggyvest’s users."
        stacked
      />

      <ImageGrid>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065509/Case%20Studies/Piggyvest/Piggyvest-LaptopMockup_eiul3p.png" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065509/Case%20Studies/Piggyvest/Piggyvest-Tote-Bag_hwe5i5.png" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065505/Case%20Studies/Piggyvest/Piggyvest-Cap-Mockup_zibavr.jpg" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065516/Case%20Studies/Piggyvest/Piggyvest-UI-Mockup-1_gmihef.png" />
      </ImageGrid>

      <BodyTitleContent
        heading="Touching every point"
        content="Most brand icons—custom-drawn or bought as a graphics pack—don’t stand out as identifiable on their own, and while this isn’t a crime, we had a unique visual feature that could influence elements as basic as icons and buttons. So, why not? We designed the elements to have at least one visibly curved edge and other relatively sharp edges based on the logo, bearing a combination of perfectly rounded edges and one relatively sharp edge. It’s a flip, but a relatable one."
        stacked
      />

      <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065506/Case%20Studies/Piggyvest/Piggyvest-Iconography-scaled_fsc93l.jpg" />

      <BodyTitleContent
        heading="Influencing the brand architecture"
        content="Piggyvest’s products—from Safelock to Investify—solve different problems across savings and investment. With a goal of higher product clarity, there was a need to design a system of mini identities that differ, yet connect to one another and most especially, to the parent brand. We followed the same design language that had influenced the icon set and employed basic illustrations of what each is about, while being minimal yet identifiable."
        stacked
      />

      <BodyTitleContent>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065505/Case%20Studies/Piggyvest/Piggyvest-Icons-scaled_ts762j.jpg" />
      </BodyTitleContent>

      <BodyTitleContent
        heading="A brand-centric user interface"
        content="We collaborated with the in-house Product Design team on a new website and mobile app interface for the platform. We aligned the look (spot the buttons on the website and mobile app) with the new visual style across board. It had to be beautiful, functional, and brand-centric."
        stacked
      />

      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065509/Case%20Studies/Piggyvest/Piggyvest-LaptopMockup_eiul3p.png" />
      <OneSideImage
        image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065510/Case%20Studies/Piggyvest/Piggyvest-Web-Screens_d1c34r.jpg"
        left
      />

      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065511/Case%20Studies/Piggyvest/Piggyvest-Mobile-Splash-screens_f6vpxf.jpg" />

      <BodyTitleContent
        heading="Finally delivering the brand guide"
        content="At the beginning of the project, the task was largely about delivering a system of standards that can help deliver consistency over time with the brand’s expressions across ads, interface and more. We created different versions and continually updated them upon prototyping and application to different purpose. At the end, we arrived at a detailed brand guide that encapsulates results from the entire process; from the brand’s key statements to the signature, colour scheme, typography, visual style, elements, and best practices in using them."
        stacked
      />

      <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065503/Case%20Studies/Piggyvest/Piggyvest-Brand-Guideline_anrjtc.jpg" />

      <BodyTitleContent
        heading="Results"
        content="While we will need to observe the long-term effect on current and potential users since this just launched, we are however happy with how much improvement we have achieved with the amazing Piggyvest team from point of engagement till now."
        stacked
      />
    </>
  )
}

export default Piggyvest
