import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import OneSideImage from "../single/one-side-image"
import ImageGrid from "../single/image-grid"

const NigerianWomenTechsters = () => {
  return (
    <>
      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065505/Case%20Studies/Nigerian%20Women%20Techsters/NWT-Logo-Full-Animation_dxqmaz.gif" />
      <OneSideImage
        image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065500/Case%20Studies/Nigerian%20Women%20Techsters/NWT-Brand-Scene_f13tbg.jpg"
        left
      />

      <ImageGrid>
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065501/Case%20Studies/Nigerian%20Women%20Techsters/NWT-Business-Card_ir5gbg.jpg" />
        <StaticImage src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065502/Case%20Studies/Nigerian%20Women%20Techsters/NWT-Flyer_nliync.jpg" />
      </ImageGrid>
      <OneSideImage image="https://res.cloudinary.com/fourthcanvas/image/upload/v1625065502/Case%20Studies/Nigerian%20Women%20Techsters/NWT-Feminine-Brand-Scene_mnmqxi.jpg" />
    </>
  )
}

export default NigerianWomenTechsters
