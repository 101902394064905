import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import BodyTitleContent from "../../../utils/title-content"
import OneSideImage from "../../single/one-side-image"
import PageContainer from "../../../utils/page-container"
import PenneeScrollShowcase from "./scroll-showcase"
import Video from "../../../utils/video"
import CaseStudyComment from "../../single/comment"
import CaseStudyCredits from "../../single/credits"

const Pennee = () => {
  return (
    <>
      <BodyTitleContent
        heading={`“Mighty things from small beginnings grow”`}
        content="We began with an intensive brand strategy exercise informed by  conversations and sprints to discover core themes. Of many things Pennee can be assumed to be, it is the single point of entry for small and micro businesses to access tools that help them scale. Now, while one may imagine that as a workstation, it is rather more of an amusement park with the tools as the pressed pennies, because like Pennee, small businesses function best further from bureaucracy and closer to fun. Plug in and play."
        stacked
      />

      <OneSideImage
        image="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384093/Case%20Studies/Pennee/Mobile/Strategy_Preview_Mobile_ll7hju.png"
        className="md:hidden flex"
        alt="Pennee Strategy"
      />
      <OneSideImage
        image="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384120/Case%20Studies/Pennee/Strategy_Preview_zpjai6.png"
        className="md:flex hidden"
        alt="Pennee Strategy"
      />

      <BodyTitleContent heading="Setting up the park" stacked>
        <>
          <p>
            Recall that Pennee gives small and micro businesses access to
            explore different tools to help them scale. You already read that,
            except for the specific word “scale” which would go on to play a
            huge role in the visual identity system. We will get there.
          </p>

          <p>
            With a conceptual direction themed “small in size, mighty in worth”,
            we explored endless ideas towards a distinctive visual language that
            captured the Pennee ideology—that small businesses drive big things,
            like the economy, and have the potential to become even bigger
            things.
          </p>
        </>
      </BodyTitleContent>

      <PageContainer>
        <img
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384136/Case%20Studies/Pennee/Photo-reel_iez1if.gif"
          className="w-full"
          alt="Pennee PhotoReel"
        />
      </PageContainer>

      <BodyTitleContent
        heading="Small. Zesty. Mighty."
        content="The core elements of the system were built around scale, potential and the dynamics of the relationship between small and big things. The furthest expression of scale came to life in the illustrations and visual elements that exaggerates the potential of magnification, with the smaller element in typical pairs not only rising up to match up with the bigger elements but switching to take superior position."
        stacked
      />

      <PageContainer className="md:hidden block">
        <img
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384097/Case%20Studies/Pennee/Mobile/Pennee_Design-Template-Mobile-3_pyx7t9.gif"
          alt="Pennee Design Template"
        />
      </PageContainer>
      <PageContainer className="md:block hidden">
        <img
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384099/Case%20Studies/Pennee/Illustrations-Preview_kah3qf.gif"
          alt="Pennee Illustrations Preview"
        />
      </PageContainer>

      <BodyTitleContent
        content="With the logo, we captured how big things are hinged on small things, taking some inspiration from the shape of the old Nigerian Penny (1959). 
"
      />

      <PageContainer className="md:hidden block">
        <img
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384091/Case%20Studies/Pennee/Mobile/Pennee-Unique-Shape-Mobile__y7mc0w.gif"
          className="w-full"
          alt="Pennee Unique Shape"
        />
      </PageContainer>
      <PageContainer className="md:block hidden">
        <img
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384122/Case%20Studies/Pennee/Pennee-Unique-Shape_w9hltf.gif"
          className="w-full"
          alt="Pennee Unique Shape"
        />
      </PageContainer>

      <BodyTitleContent
        content="The relationship between the two dynamic elements within the icon (the square and the Pennee coin) tells a story of the dynamic relationship between Pennee (growth partner) and small businesses, as well as between small businesses and the economy."
        stacked
      />

      <OneSideImage>
        <Video
          poster="https://res.cloudinary.com/fourthcanvas/image/upload/v1627449874/Case%20Studies/Pennee/video_thumbnail_vw0coo.jpg"
          src="https://res.cloudinary.com/fourthcanvas/video/upload/v1627384112/Case%20Studies/Pennee/Pennee_Logo_Preview_x6ryzj.mp4"
        />
      </OneSideImage>

      <OneSideImage
        image="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384114/Case%20Studies/Pennee/Pennee_Typography_bkvgb7.gif"
        alt="Pennee Typography"
        left
      />
      <OneSideImage
        image="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384110/Case%20Studies/Pennee/Pennee_Colour_wvscfk.gif"
        alt="Pennee Colour "
      />

      <BodyTitleContent>
        <>
          <p>
            With the colours, we were able to reflect what interacting with
            Pennee feels like. Whether the lights are on or off, at the office
            or on a call with a merchant, the perception remains the same; fun,
            empathetic and informed.{" "}
          </p>
          <p>
            On our choices on type, we sought first after uniqueness and
            function, but also with a unique character and versatility; the
            potential for further exploration, as would rightly fit into the
            theme we were establishing.{" "}
          </p>
        </>
      </BodyTitleContent>

      <PageContainer className="md:hidden block">
        <img
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384102/Case%20Studies/Pennee/Mobile/Pennee-Pattern-Mobile_ya90fe.gif"
          alt="Pennee Pattern"
        />
        <img
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384084/Case%20Studies/Pennee/Mobile/Pennee-Icon-Mobile_lqidjo.gif"
          alt="Pennee Icon"
        />
      </PageContainer>
      <PageContainer className="md:block hidden">
        <img
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384126/Case%20Studies/Pennee/Pennee-Pattern-Icon_acu61e.gif"
          alt="Pennee Pattern Icon"
        />
      </PageContainer>

      <BodyTitleContent
        content="The new Pennee identity captures a spirit and personality that runs through the company and its approach to problem-solving—from the rebellious founder to the tiniest bits of its unique approach to helping businesses grow. We have tried to capture that and it’s taking its full form."
        stacked
      />

      <PenneeScrollShowcase />

      <PageContainer className="md:hidden block">
        <img
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384097/Case%20Studies/Pennee/Mobile/Pennee_Design-Template-Mobile-3_pyx7t9.gif"
          alt="Pennee Design Template"
        />
      </PageContainer>

      <PageContainer className="md:hidden block">
        <StaticImage
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384087/Case%20Studies/Pennee/Mobile/Mockup_Mobile_1_azvg1o.png"
          alt="Pennee Mockup"
        />
      </PageContainer>

      <PageContainer className="md:hidden block">
        <StaticImage
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1627384085/Case%20Studies/Pennee/Mobile/Mockup_Mobile_2_bltjxb.png"
          alt="Pennee Mockup"
        />
      </PageContainer>

      <CaseStudyComment
        comment="FourthCanvas understood us so much they embodied our values right from the execution process itself. We were already impressed before the final results came in. Today, our work at Pennee is significantly easier because of the work they did with us"
        by="Mejero Emmanuella"
      />

      <CaseStudyCredits
        color="text-pennee-brown"
        people={[
          {
            name: "Mary Afolabi",
            role: "for leading our team on the project",
          },
          {
            name: "Opeyemi Olugbemiro",
            role: "for leading rediscovery with brand strategy",
          },
          {
            name: "Dunsin Ayodele",
            role: "for the amazing ideas, expression and motion genius",
          },
          {
            name: "Ademola Aderibigbe",
            role: "for the unmistakable illustrations",
          },
        ]}
        shoutout={
          <>
            Special thanks to <b>Mejero Emmanuella & Gideon (Progee)</b>—from
            the Pennee team—for being so sweet to work with and encouraging the
            transformative power of change.
          </>
        }
      />
    </>
  )
}

export default Pennee
