import React from "react"

const CaseStudyCreditItem = ({ person }) => {
  return (
    <li>
      <b>{person.name}</b> - {person.role}
    </li>
  )
}

export default CaseStudyCreditItem
