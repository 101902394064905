import React from "react"
import Lottie from "lottie-react"
import PageContainer from "../../../../utils/page-container"

import StarIcon from "./star_icon.json"
import ProfileIcon from "./profile_icon.json"
import TransactionIcon from "./transaction_icon.json"

const FundallAnimatedIcons = () => {
  return (
    <PageContainer>
      <div className=" md:px-28 px-8 xl:py-48 md:py-32 py-8 place-items-center grid md:grid-cols-3 grid-cols-2 xl:gap-[200px] lg:gap-[100px] gap-[30px] border-[1px] border-black">
        <Lottie animationData={ProfileIcon} loop={true} autoplay={true} />
        <Lottie animationData={TransactionIcon} loop={true} autoplay={true} />
        <Lottie animationData={StarIcon} loop={true} autoplay={true} />
        <div className="bg-fundall-green w-full h-full md:hidden block"></div>
      </div>
    </PageContainer>
  )
}

export default FundallAnimatedIcons
