import React from "react"
import Wrapper from "../../utils/wrapper"

import parse from "html-react-parser"

const WorkIntro = ({ client, projectType = [], title = "", description }) => {
  const isThereProjectTypes = projectType.length > 0

  const project_types = isThereProjectTypes
    ? projectType.map(projectType => projectType.name)
    : []

  const infoStyle = `md:text-xl text-base`
  const projectDetails = (
    <div className="sm:block hidden">
      <h3 className={infoStyle}>Client</h3>
      <p className="-mt-2">{client}</p>

      {/* Check if project types were set */}
      {isThereProjectTypes ? (
        // Show Markup with data
        <>
          <h3 className={`mt-8 ${infoStyle}`}>Project Type:</h3>
          <p className="-mt-2">{project_types.join(", ")}</p>
        </>
      ) : null}
    </div>
  )

  const projectIntro = (
    <>
      <h2>{title}</h2>
      <p>{parse(description)}</p>
    </>
  )
  return <Wrapper leftContent={projectDetails} centerContent={projectIntro} />
}

export default WorkIntro
