import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import BodyTitleContent from "../../../utils/title-content"
import CaseStudyCredits from "../../single/credits"
import OneSideImage from "../../single/one-side-image"
import ImageGrid from "../../single/image-grid"
import Video from "../../../utils/video"

const Nomba = () => {
  return (
    <>
      <OneSideImage>
        <StaticImage
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722164/Case%20Studies/Nomba/nomba-case-study/02_-_Nomba-logo-on-black-bg-fourthcanvas_rj01fi.png"
          alt="Nomba formerly Kudi"
          className="mb-4"
        />

        <ImageGrid gap>
          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722165/Case%20Studies/Nomba/nomba-case-study/03_-_Nomba_app_icon-fourthcanvas_yzbalq.png"
            alt="Nomba Icon by FourthCanvas"
          />

          <img
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722173/Case%20Studies/Nomba/nomba-case-study/04_-_Icon_construction_-_fourthcanvas_mymget.gif"
            alt="Nomba logo construction"
          />
        </ImageGrid>
      </OneSideImage>
      <BodyTitleContent heading="Show me the Nomba" stacked>
        <>
          <p>
            The new name takes inspiration from the word “numbers” — often
            underrated and yet at the centre of how we process information. For
            most businesses, numbers are the most basic way to identify
            problems, define goals, measure growth, and make money.
          </p>
          <p>
            The change from “Kudi” alludes the company’s new promise to improve
            how everyday businesses accept payments and manage their business
            operations. The name, “Nomba”, works perfectly, common to the target
            audience lingua, easily understandable while still unique within the
            competitive arena.
          </p>
        </>
      </BodyTitleContent>
      <OneSideImage>
        <Video
          src="https://res.cloudinary.com/fourthcanvas/video/upload/v1668722162/Case%20Studies/Nomba/nomba-case-study/05_-_Kudi_to_nomba_transition_-_fourthcanvas.png_w0wc4e.mp4"
          gif
          className="mb-4"
          poster="https://res.cloudinary.com/fourthcanvas/image/upload/v1669795224/Case%20Studies/Nomba/nomba-case-study/video-posters/05_-_Kudi_to_nomba_transition_-_fourthcanvas_stx7up.png"
        />

        <ImageGrid gap>
          <Video
            src="https://res.cloudinary.com/fourthcanvas/video/upload/v1670047517/Case%20Studies/Nomba/nomba-case-study/06_-_Motion_cuts_showing_numbers_-_fourthcanvas2_eq20is.mp4"
            gif
          />

          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722163/Case%20Studies/Nomba/nomba-case-study/07_-_Nomba_casing_-_fourthcanvas_k9uchs.png"
            alt="Nomba Casing"
          />
        </ImageGrid>
      </OneSideImage>

      <BodyTitleContent heading="Everybody pays for something" stacked>
        <>
          <p>
            It’s 2022. Trading is an integral part of the average person’s day.
            Every second, someone is paying for something. The main design
            question was “how do we create an identity which communicates how
            ubiquitous payment is in people’s lives?”
          </p>
          <p>
            The symbol is a slightly abstract representation of cross-road signs
            — typically seen in the middle of road intersections across the
            country. It communicates the idea that regardless of what your
            payment needs are, Nomba can provide and guide you to the right
            solution. The logo icon is a key part of the system, constantly
            applied with other visual devices such as colour, patterns, icons,
            etc.
          </p>
        </>
      </BodyTitleContent>

      <OneSideImage className="">
        <ImageGrid gap>
          <Video
            src="https://res.cloudinary.com/fourthcanvas/video/upload/v1668722192/Case%20Studies/Nomba/nomba-case-study/08_-_Stop_motion_of_shirt_-_fourthcanvas_bmyb3j.mp4"
            gif
            poster="https://res.cloudinary.com/fourthcanvas/image/upload/v1669795224/Case%20Studies/Nomba/nomba-case-study/video-posters/08_-_Stop_motion_of_tshirt_-_fourthcanvas_ns7pdu.png"
          />

          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722163/Case%20Studies/Nomba/nomba-case-study/09_-_Stationery_mockup_-_fourthcanvas_qezdso.png"
            alt="Nomba Stationery Mockup"
          />
        </ImageGrid>
        <StaticImage
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1670047517/Case%20Studies/Nomba/nomba-case-study/10_-_Brand_guideline_-_fourthcanvas_1_do5kcx.png"
          alt="Nomba Brand Guideline"
          className="my-4"
        />
        <ImageGrid gap>
          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722161/Case%20Studies/Nomba/nomba-case-study/11_-_Laptop_sticker_-_fourthcanvas_auhujw.png"
            alt="Nomba Laptop Sticker"
          />
          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722164/Case%20Studies/Nomba/nomba-case-study/12_-_Totebag_mockup_-_fourthcanvas_g5bepc.png"
            alt="Nomba Tote bag"
          />
          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722165/Case%20Studies/Nomba/nomba-case-study/13_-_Pillow_mockup_-_fourthcanvas_pz6mnt.png"
            alt="Nomba Pillow Mockup"
          />
          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722165/Case%20Studies/Nomba/nomba-case-study/14_-_Random_brand_mockup_-_fourthcanvas_mtnofa.png"
            alt="Nomba Tshirt"
          />
        </ImageGrid>
      </OneSideImage>

      <BodyTitleContent heading="For millions of people and businesses" stacked>
        <>
          <p>
            We created visual devices to communicate a unique identity for Nomba
            everywhere — easily recognised by anyone. We developed a new visual
            language across colour, typography, patterns, and imagery to ensure
            consistency regardless of where it is being applied.
          </p>
          <p>
            The identity system also appeals to its key audience segments — the
            banked, underbanked and unbanked. It provides the right amount of
            sophistication to an urban audience, yet simple enough to be
            meaningful to rural communities.
          </p>
        </>
      </BodyTitleContent>

      <OneSideImage>
        <ImageGrid gap>
          <img
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722166/Case%20Studies/Nomba/nomba-case-study/15_-_Typography_in_motion_-_fourthcanvas_zxampv.gif"
            alt="Typography in Motion - Nomba"
          />

          <img
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722165/Case%20Studies/Nomba/nomba-case-study/16_-_Color_in_motion_-_fourthcanvas_xan3ny.gif"
            alt="Color in Motion - Nomba"
          />
        </ImageGrid>
        <img
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722167/Case%20Studies/Nomba/nomba-case-study/17_-_Nomba_Imagery_-_fourthcanvas_uamc8x.png"
          alt="Nomba imagery"
          className="my-4"
        />
        <ImageGrid gap>
          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1670936300/Case%20Studies/Nomba/nomba-case-study/18_-_Arrow_icons_-_fourthcanvas_rhbfwy.png"
            alt="Nomba arrow icons"
          />

          <img
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722164/Case%20Studies/Nomba/nomba-case-study/19_-_We_mean_business_-_fourthcanvas_tcfrfn.gif"
            alt="We mean business"
          />

          <Video
            src="https://res.cloudinary.com/fourthcanvas/video/upload/v1669695494/Case%20Studies/Nomba/nomba-case-study/20_-_Stop_motion_of_packaging_box_-_fourthcanvas_q7asm8.mp4"
            gif
            poster="https://res.cloudinary.com/fourthcanvas/image/upload/v1669795224/Case%20Studies/Nomba/nomba-case-study/video-posters/19_-_Stop_motion_of_packaging_boxes_-_fourthcanvas_cexzpm.png"
          />
          <Video
            src="https://res.cloudinary.com/fourthcanvas/video/upload/v1668722167/Case%20Studies/Nomba/nomba-case-study/21_-_Ball_hitting_bottle_-_fourthcanvas_libwnh.mp4"
            gif
            poster="https://res.cloudinary.com/fourthcanvas/image/upload/v1669795224/Case%20Studies/Nomba/nomba-case-study/video-posters/21_-_Ball_hitting_bottle_-_fourthcanvas_yxghjv.png"
          />
        </ImageGrid>
      </OneSideImage>

      <BodyTitleContent heading="When numbers sing" stacked>
        <>
          <p>
            Designed in collaboration with talented musician and producer,
            Damilare Afolabi, the Nomba sonic identity was inspired by the
            familiar bits and pieces of Nigerian memories that included numbers.
          </p>
          <p>
            The sound of chalk on the math board, claps from the “tinko-tinko”
            game, ludo, “ten-ten” — we created an album of sounds that helps
            Nomba unique communicate its brand via channels that support audio
            (social media, radio, television, etc).
          </p>
        </>
      </BodyTitleContent>

      <OneSideImage>
        <ImageGrid gap className="mb-4">
          <Video
            src="https://res.cloudinary.com/fourthcanvas/video/upload/v1668722186/Case%20Studies/Nomba/nomba-case-study/22_-_When_numbers_sing_-_fourthcanvas_wuwgkp.mp4"
            gif
            poster="https://res.cloudinary.com/fourthcanvas/image/upload/v1669795224/Case%20Studies/Nomba/nomba-case-study/video-posters/22_-_When_numbers_sing_-_fourthcanvas_l5hbqz.png"
          />
          <div style={{ padding: "75% 0 0 0", position: "relative" }}>
            <iframe
              src="https://player.vimeo.com/video/771672683?h=16b81c258b&title=0&byline=0&portrait=0"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              frameborder="0"
              allow="autoplay;"
            ></iframe>
          </div>
        </ImageGrid>

        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            src="https://player.vimeo.com/video/771680839?h=19db7b6018&title=0&byline=0&portrait=0&app_id=58479"
            frameborder="0"
            allow="autoplay; "
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
            title="22 - NOMBA LOGO ANIMATION WITH SOUND 2"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </OneSideImage>

      <BodyTitleContent
        heading="A seamless brand experience, end to end"
        stacked
      >
        <>
          <p>
            As we prepared for the launch of the new brand, we designed and
            developed the Nomba website — understanding that it is the HQ of any
            brand’s presence on the internet. We also worked closely with the
            Nomba team to create assets for the product design.
          </p>
          <p>
            We co-created expanded style guides strictly for digital uses and
            built a system for the product team to use in further iteration as
            they gain feedback and learn more from their customers.
          </p>
        </>
      </BodyTitleContent>

      <OneSideImage>
        <Video
          src="https://res.cloudinary.com/fourthcanvas/video/upload/v1668722183/Case%20Studies/Nomba/nomba-case-study/25_-_Safari_website_video_-_fourthcanvas_fgzikf.mp4"
          gif
          poster="https://res.cloudinary.com/fourthcanvas/image/upload/v1669795225/Case%20Studies/Nomba/nomba-case-study/video-posters/25_-_Safari_website_video_-fourthcanvas_iuadrw.png"
        />

        <ImageGrid gap className="mt-4">
          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722162/Case%20Studies/Nomba/nomba-case-study/26_-_Safari_mockup_-_website_-_fourthcanvas_yeuy2s.png"
            alt="Nomba website shot"
          />
          <img
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722164/Case%20Studies/Nomba/nomba-case-study/27_-_Nomba_transition_mockup_-_fourthcanvas_xvdjyf.gif"
            alt="Nomba transition on mobile"
          />
          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722162/Case%20Studies/Nomba/nomba-case-study/28_-_Webapp_cut_01_-_fourthcanvas_o64okc.png"
            alt="Nomba Terminals Overview"
          />
          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722165/Case%20Studies/Nomba/nomba-case-study/29_-_Web_app_cut-out_02_-_fourthcanvas_lzmym6.png"
            alt="Nomba Terminals Detail"
          />
        </ImageGrid>
      </OneSideImage>

      <BodyTitleContent heading="If you mean business" stacked>
        <p>
          We worked with the Nomba team and numerous media and advertising
          agencies to launch the new brand to the world. Some of the assets
          created were OOH ads, billboards, TV commercials, radio jingles, etc
          distributed nationwide.
        </p>
      </BodyTitleContent>

      <OneSideImage>
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            src="https://player.vimeo.com/video/771681035?h=3a4690cdcb&title=0&byline=0&portrait=0app_id=58479"
            frameborder="0"
            allow="autoplay;"
            allowfullscreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="28 -  OOH ADS WITH NOMBA REPEATED"
          ></iframe>
        </div>
        <ImageGrid gap className="my-4">
          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722162/Case%20Studies/Nomba/nomba-case-study/31_-_Ooh_lamppost_-_fourthcanvas_w46awm.png"
            alt="Nomba Lampost with Nedu on it"
          />
          <StaticImage
            src="https://res.cloudinary.com/fourthcanvas/image/upload/v1668722163/Case%20Studies/Nomba/nomba-case-study/32_-_Ooh_billboard_-_fourthcanvas_o9j5jn.png"
            alt="Nomba Lagos Billboard - Toke Makinwa"
          />
        </ImageGrid>
        <Video
          src="https://res.cloudinary.com/fourthcanvas/video/upload/v1668722205/Case%20Studies/Nomba/nomba-case-study/33_-_Event_launch_video_-_fourthcanvas_bczhlq.mp4"
          gif
        />
      </OneSideImage>

      <CaseStudyCredits
        color="text-black"
        people={[
          {
            name: "Ayomide Ajayi",
            role: "for leading on renaming and marketing launch strategy ",
          },
          {
            name: "Opeyemi Olugbemiro",
            role: "for his detailed work on brand strategy.",
          },
          {
            name: "Tunji Ogunoye",
            role:
              "for leading design on the project, and co-supervising implementation on the launch",
          },
          {
            name: "Alamu Temitayo and Dunsin Ayodele",
            role:
              "for their great work on design execution (both still and motion)",
          },
          {
            name: "Ola Adedamola and Aluko Brown",
            role: "for their amazing work on the digital interfaces",
          },
          {
            name: "Faith Adeoye",
            role: "for the impressive content development work on the website",
          },
          {
            name: "Aremu Oluwagbamila, Hafsah Emekoma, and Chineta Adinnu",
            role: "for their stellar work building the website",
          },
          {
            name:
              "Felix Ayoola, Samuel Jolayemi, Ademola Aderibigbe and Teslim Abu",
            role: "for their contributions",
          },
          {
            name: "Praise Philemon",
            role: "for coordinating the production of this case study",
          },
          {
            name: "Victor Fatanmi",
            role:
              "for his overall leadership, creative direction and brand management",
          },
        ]}
        shoutout={
          <>
            <b>Yinka Adewale, Pelumi Aboluwarin and Toluwani Ijogun</b> - for
            being incredible clients to work with, leading from within and
            trusting us on this partnership
          </>
        }
      />
    </>
  )
}

export default Nomba
