import React from "react"
import PageContainer from "../../../../utils/page-container"

const FundallTypographyIconography = () => {
  const type_header_stlye = `uppercase text-xs mb-2`
  return (
    <section className="bg-[#F5F9F8] md:pt-36 pt-24 pb-16">
      <PageContainer>
        <img
          alt="Fundall Iconography"
          src="https://res.cloudinary.com/fourthcanvas/image/upload/v1625150857/Case%20Studies/Fundall/iconography_ntenc5.svg"
          className="mx-auto block"
        />

        <section>
          <section className="max-w-[500px] mx-auto mt-16 grid md:grid-cols-2 grid-cols-1 gap-6 text-fundall-green justify-between">
            <div>
              <p className={type_header_stlye}>Founder's Grotesk</p>
              <h3 className="mt-0">Smart, as your bank should be</h3>
            </div>
            <div>
              <p className={type_header_stlye}>Messina Sans</p>

              <p className="text-sm text-black mb-0">
                Get help planning, saving and investing in ways that support
                your lifestyle and manage your money intelligently.
              </p>
            </div>
          </section>
        </section>
      </PageContainer>
    </section>
  )
}

export default FundallTypographyIconography
